import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {ViewStateType} from "../../types/global.type";

@Component({
    selector: 'app-view-state',
    templateUrl: './view-state.component.html',
    styleUrls: ['./view-state.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ViewStateComponent implements OnInit {
    @Input() public state: ViewStateType = "normal";
    @Input() public header!: string;
    @Input() public text!: string;

    public static readonly normalState: ViewStateType = "normal";
    public static readonly successState: ViewStateType = "success";
    public static readonly errorState: ViewStateType = "error";

    constructor() {
    }

    ngOnInit(): void {
    }

}

import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ApplicationCourseFormOpenService} from "./application-course-form-open.service";
import {Subscription} from "rxjs";
import {UtilsService} from "../../../services/utils.service";
import {CourseDateInterface, CourseRegistrationActiveCourseInterface, CourseRegistrationOpenActiveInterface, CourseRegistrationOpenActiveRegistrationInterface} from "../../../interfaces/course.interface";
import {DictionaryAddresseeInterface, DictionaryCourseTemplateInterface, DictionaryEstablishmentTypeInterface, DictionaryFunctionInterface, DictionarySubjectInterface} from "../../../interfaces/dictionary.interface";
import {DictionaryAddresseesService} from "../../../pages/dictionary/dictionary-addressees/dictionary-addressees.service";
import {EmailControlValidate} from "../../../form-validators/email.validator";
import {CountryStateInterface} from "../../../interfaces/global.interface";
import {CountryStatesService} from "../../../services/country-states.service";
import {CountriesService} from "../../../services/countries.service";
import {DictionarySubjectsService} from "../../../pages/dictionary/dictionary-subjects/dictionary-subjects.service";
import {DictionaryEstablishmentTypesService} from "../../../pages/dictionary/dictionary-establishment-types/dictionary-establishment-types.service";
import {NipControlValidate} from "../../../form-validators/nip.validator";
import {CoursesService} from '../../../pages/courses/courses.service';
import {CourseTypesEnum} from '../../../enums/course-types.enum';
import * as _ from 'underscore';
import {CustomersService} from '../../../pages/customers/customers.service';
import {ActivatedRoute, Params} from '@angular/router';
import {DictionaryFunctionsService} from '../../../pages/dictionary/dictionary-functions/dictionary-functions.service';
import {CustomerTypesEnum} from '../../../enums/customer-types.enum';
import {ApplicationCourseFormType} from '../../../types/global.type';
import {DictionaryCourseTemplatesService} from '../../../pages/dictionary/dictionary-course-templates/dictionary-course-templates.service';
import {ToastService} from '../../../services/toast.service';
import {PostalCodeControlValidate} from '../../../form-validators/postal-code.validator';
import {FileUploadService} from '../../../services/file-upload.service';
import {TranslateService} from '../../../services/translate.service';

@Component({
    selector: 'app-application-course-form-open',
    templateUrl: './application-course-form-open.component.html',
    styleUrls: ['./application-course-form-open.component.scss']
})
export class ApplicationCourseFormOpenComponent implements OnInit, OnDestroy {
    @Input() public formType: ApplicationCourseFormType = 'customer';
    @Input() public activeCourseId!: string;
    @Output() public existingUser: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() public establishmentUser: EventEmitter<boolean> = new EventEmitter<boolean>();
    public form!: FormGroup;
    private subscriptions: Subscription[] = [];
    public statics: ApplicationCourseFormOpenStatic;
    public filesToDownload: string[] = [];

    constructor(private applicationCourseFormOpenService: ApplicationCourseFormOpenService,
                private formBuilder: FormBuilder,
                private coursesService: CoursesService,
                private dictionaryAddresseesService: DictionaryAddresseesService,
                private dictionarySubjectsService: DictionarySubjectsService,
                private dictionaryEstablishmentTypesService: DictionaryEstablishmentTypesService,
                private dictionaryFunctionsService: DictionaryFunctionsService,
                private dictionaryCourseTemplatesService: DictionaryCourseTemplatesService,
                private customersService: CustomersService,
                private activatedRoute: ActivatedRoute,
                private toastService: ToastService,
                private translateService: TranslateService) {
        this.statics = new ApplicationCourseFormOpenStatic(formBuilder, coursesService, dictionaryAddresseesService, dictionarySubjectsService, dictionaryEstablishmentTypesService, dictionaryFunctionsService, dictionaryCourseTemplatesService, activatedRoute);
        this.createForm();
        this.resetFormChanged();
    }

    private resetFormChanged(): void {
        this.applicationCourseFormOpenService.resetFormChanged().subscribe(() => {
            if (!!this.form) this.form.reset();
        });
    }

    ngOnInit(): void {
        setTimeout(() => this.prepareFilesToDownload(), 2000);
    }

    ngOnDestroy() {
        UtilsService.unsubscribeAll(this.subscriptions);
    }

    private createForm(): void {
        this.form = this.statics.createForm();
        this.formChanged();
    }

    private formChanged(): void {
        this.setForm();
        this.subscriptions.push(this.form.valueChanges.subscribe(() => {
            this.setForm();
        }));
    }

    private setForm(): void {
        this.applicationCourseFormOpenService.form = this.form;
    }

    public getParticipantDataFG(): FormGroup {
        return this.form.get('participantData') as FormGroup;
    }

    public getWorkPlaceFG(): FormGroup {
        return this.form.get('workPlace') as FormGroup;
    }

    public getRecipientFG(): FormGroup {
        return this.form.get('invoiceReceiverData') as FormGroup;
    }

    public getBuyerFG(): FormGroup {
        return this.form.get('invoiceBuyerData') as FormGroup;
    }

    public ifError(name: string): boolean {
        return ApplicationCourseFormOpenComponent.ifFGError(<FormGroup>this.form.get(name));
    }

    public ifParticipantDataError(name: string): boolean {
        return ApplicationCourseFormOpenComponent.ifFGError(<FormGroup>this.getParticipantDataFG().get(name));
    }

    public ifWorkPlaceError(name: string): boolean {
        return ApplicationCourseFormOpenComponent.ifFGError(<FormGroup>this.getWorkPlaceFG().get(name));
    }

    public ifInvoiceBuyerDataError(name: string): boolean {
        return ApplicationCourseFormOpenComponent.ifFGError(<FormGroup>this.getBuyerFG().get(name));
    }

    public ifInvoiceRecipientError(name: string): boolean {
        return ApplicationCourseFormOpenComponent.ifFGError(<FormGroup>this.getRecipientFG().get(name));
    }

    private static ifFGError(fg: FormGroup): boolean {
        return fg.invalid && fg.touched;
    }

    public downloadCustomerByData(propertyName: string, formPropertyName: string): void {
        if (this.formType === 'administration') {
            const value: string = this.getWorkPlaceFG().get(formPropertyName)?.value;
            if (!!value) this.customersService.toDownloadCustomerByData(propertyName, value, this.getWorkPlaceFG(), this.getBuyerFG(), this.getRecipientFG(), this.ifCustomerTypeEstablishment() ? this.getParticipantDataFG() : undefined).then(() => {
                this.checkIfAssignAsParticipant();
            });
        }
    }

    public courseTemplateChanged(): void {
        this.form.get('activeCourseId')?.reset();
    }

    public get activeCourseRealization(): CourseRegistrationOpenActiveRegistrationInterface | undefined {
        return this.getChosenCourse()?.realizations?.find((realization: CourseRegistrationOpenActiveRegistrationInterface) => realization.id === this.form.get('activeCourseId')?.value);
    }

    public prepareFilesToDownload(): void {
        this.filesToDownload = this.getChosenCourse()?.realizations?.find((realization: CourseRegistrationOpenActiveRegistrationInterface) => realization.id === this.form.get('activeCourseId')?.value)?.filesToDownload || [];
        this.form.get('filesToDownloadAgreement')?.setValidators(this.filesToDownload.length > 0 ? Validators.requiredTrue : null);
        this.form.updateValueAndValidity();
    }

    public openFileToDownload(fileToDownloadIdx: number): void {
        FileUploadService.showFileInNewPage(this.filesToDownload[fileToDownloadIdx]);
    }

    public getChosenCourse(): CourseRegistrationOpenActiveInterface {
        return this.statics.courses.find((course: CourseRegistrationOpenActiveInterface) => course.templateId === this.form.get('courseTemplateId')?.value)!;
    }

    public ifCustomerTypeIndividual(): boolean {
        return this.form.get('customerType')?.value === this.statics.individualCustomer;
    }

    public ifCustomerTypeEstablishment(): boolean {
        return this.form.get('customerType')?.value === this.statics.establishmentCustomer;
    }

    public ifApplicationFormTypeCustomers(): boolean {
        return UtilsService.ifApplicationFormTypeCustomer(this.formType);
    }

    public ifApplicationFormTypeAdministration(): boolean {
        return UtilsService.ifApplicationFormTypeAdministration(this.formType);
    }

    public checkIfAssignAsParticipant(): void {
        if (this.ifApplicationFormTypeAdministration() && !!this.activeCourseId) this.form.get('activeCourseId')?.setValue(this.activeCourseId);
        if (this.ifCustomerTypeIndividual() && !!this.getParticipantDataFG().get('email')?.value) {
            this.checkIfEstablishmentParticipant();
            if (!!this.form.get('activeCourseId')?.value) {
                this.applicationCourseFormOpenService.checkIfAssignAsParticipant(this.form.get('activeCourseId')?.value, this.getParticipantDataFG().get('email')?.value).subscribe(() => {
                    this.existingUser.emit(true);
                    this.toastService.translateText('warning', 'warning.existingParticipantInActiveCourse');
                }, () => this.existingUser.emit(false));
            }
        }
    }

    private checkIfEstablishmentParticipant(): void {
        this.applicationCourseFormOpenService.checkIfEstablishmentParticipant(this.getParticipantDataFG().get('email')?.value).subscribe((customerType: CustomerTypesEnum) => {
            if (customerType === CustomerTypesEnum.ESTABLISHMENT) {
                this.establishmentUser.emit(true);
                alert(this.translateService.getTranslated('warning.existingParticipantIsEstablishmentType'));
            }
            else this.establishmentUser.emit(false);
        }, () => this.establishmentUser.emit(false));
    }
}

class ApplicationCourseFormOpenStatic {
    public courses: CourseRegistrationOpenActiveInterface[] = [];
    public addressees: DictionaryAddresseeInterface[] = [];
    public subjects: DictionarySubjectInterface[] = [];
    public establishmentTypes: DictionaryEstablishmentTypeInterface[] = [];
    public states: CountryStateInterface[] = [];
    public functions: DictionaryFunctionInterface[] = [];
    public courseTemplates: DictionaryCourseTemplateInterface[] = [];
    public individualCustomer: number = CustomerTypesEnum.INDIVIDUAL;
    public establishmentCustomer: number = CustomerTypesEnum.ESTABLISHMENT;

    constructor(private formBuilder: FormBuilder,
                private coursesService: CoursesService,
                private dictionaryAddresseesService: DictionaryAddresseesService,
                private dictionarySubjectsService: DictionarySubjectsService,
                private dictionaryEstablishmentTypesService: DictionaryEstablishmentTypesService,
                private dictionaryFunctionsService: DictionaryFunctionsService,
                private dictionaryCourseTemplatesService: DictionaryCourseTemplatesService,
                private activatedRoute: ActivatedRoute) {
        this.getData();
        this.downloadCourses();
    }

    private downloadCourses(): void {
        this.coursesService.downloadActivesList().subscribe((list: CourseRegistrationActiveCourseInterface[]) => {
            this.prepareListCourses(list);
        });
    }

    private prepareListCourses(activeCourses: CourseRegistrationActiveCourseInterface[]): void {
        this.courses = [];
        _.sortBy<CourseRegistrationActiveCourseInterface[]>(activeCourses.filter((activeCourse: CourseRegistrationActiveCourseInterface) => activeCourse.type === CourseTypesEnum.OPEN), 'startDate')
            .forEach((activeCourse: CourseRegistrationActiveCourseInterface) => {
                const foundCoursesIdx: number = this.courses.findIndex((course: CourseRegistrationOpenActiveInterface) => course.templateId === activeCourse.templateId);
                if (UtilsService.ifIdx(foundCoursesIdx)) this.courses[foundCoursesIdx].realizations.push(ApplicationCourseFormOpenStatic.prepareListCoursesGetCourseRealization(activeCourse));
                else this.courses.push(ApplicationCourseFormOpenStatic.prepareListCoursesGetCourse(activeCourse, this.courseTemplates.find((courseTemplate: DictionaryCourseTemplateInterface) => courseTemplate.id === activeCourse.templateId)));
            });
    }

    private static prepareListCoursesGetCourse(activeCourse: CourseRegistrationActiveCourseInterface, courseTemplate?: DictionaryCourseTemplateInterface): CourseRegistrationOpenActiveInterface {
        return {
            templateId: activeCourse.templateId,
            name: activeCourse.templateName || activeCourse.name || courseTemplate?.name!,
            realizations: [ApplicationCourseFormOpenStatic.prepareListCoursesGetCourseRealization(activeCourse)]
        }
    }

    private static prepareListCoursesGetCourseRealization(activeCourse: CourseRegistrationActiveCourseInterface): CourseRegistrationOpenActiveRegistrationInterface {
        return {
            id: activeCourse.id,
            startDate: activeCourse.startDate,
            startHour: activeCourse.startHour,
            locationName: (!!activeCourse.localizations && !!activeCourse.localizations[0]) ? activeCourse.localizations[0].name : '',
            displayLabel: activeCourse.dates.map((date: CourseDateInterface) => `${date.date} ${date.startHour} - ${date.endHour}`).join('; '),
            filesToDownload: activeCourse.filesToDownload || [],
            showInCalendar: activeCourse.showInCalendar,
            showInForm: activeCourse.showInForm,
        }
    }

    private getData(): void {
        this.addressees = this.dictionaryAddresseesService.data;
        this.subjects = this.dictionarySubjectsService.data;
        this.establishmentTypes = this.dictionaryEstablishmentTypesService.data;
        this.functions = this.dictionaryFunctionsService.data;
        this.states = CountryStatesService.getStatesByCountry(CountriesService.valPL);
        this.courseTemplates = this.dictionaryCourseTemplatesService.data;
    }

    public createForm(): FormGroup {
        const params: Params = this.activatedRoute.snapshot.queryParams;
        const courseTemplateId: string = !!params['courseTemplateId'] ? params['courseTemplateId'] : null;
        const activeCourseId: string = !!params['activeCourseId'] ? params['activeCourseId'] : null;
        const courseOrganizer: boolean = !!params['courseOrganizer'];
        const customerType: number | null = courseOrganizer ? CustomerTypesEnum.ESTABLISHMENT : (!!params['customerType'] ? +params['customerType'] : null);

        return this.formBuilder.group({
            courseTemplateId: new FormControl(courseTemplateId, Validators.required),
            activeCourseId: new FormControl(activeCourseId, Validators.required),
            customerType: new FormControl(customerType, Validators.required),
            courseOrganizer: new FormControl(courseOrganizer, Validators.required),
            participantCount: new FormControl(null),
            subscription: new FormControl(false),
            bonus: new FormControl(false),
            filesToDownloadAgreement: new FormControl(false),
            email: new FormControl(null, EmailControlValidate),
            participantData: this.createFormParticipantData(),
            workPlace: this.createFormWorkPlace(),
            invoiceBuyerData: this.createFormInvoiceDataBuyer(),
            invoiceReceiverData: this.createFormInvoiceDataRecipient()
        });
    }

    private createFormParticipantData(): FormGroup {
        return this.formBuilder.group({
            firstName: new FormControl(null, Validators.required),
            lastName: new FormControl(null, Validators.required),
            function: new FormControl(null, Validators.required),
            topic: new FormControl(null, Validators.required),
            email: new FormControl(null, [Validators.required, EmailControlValidate]),
            phone: new FormControl(null, Validators.required),
        });
    }

    private createFormWorkPlace(): FormGroup {
        return this.formBuilder.group({
            hash: new FormControl(null),
            type: new FormControl(null, Validators.required),
            name: new FormControl(null, Validators.required),
            email: new FormControl(null, [Validators.required, EmailControlValidate]),
            phone: new FormControl(null),
            state: new FormControl(null, Validators.required),
            commune: new FormControl(null),
            city: new FormControl(null, Validators.required),
            district: new FormControl(null),
            postalCode: new FormControl(null, [Validators.required, PostalCodeControlValidate]),
            street: new FormControl(null, Validators.required),
            buildingNumber: new FormControl(null, Validators.required),
            localNumber: new FormControl(null)
        });
    }

    private createFormInvoiceDataBuyer(): FormGroup {
        return this.formBuilder.group({
            name: new FormControl(null),
            nip: new FormControl(null, NipControlValidate),
            city: new FormControl(null),
            postalCode: new FormControl(null, PostalCodeControlValidate),
            street: new FormControl(null),
            buildingNumber: new FormControl(null),
            localNumber: new FormControl(null)
        });
    }

    private createFormInvoiceDataRecipient(): FormGroup {
        return this.formBuilder.group({
            name: new FormControl(null),
            city: new FormControl(null),
            postalCode: new FormControl(null, PostalCodeControlValidate),
            street: new FormControl(null),
            buildingNumber: new FormControl(null),
            localNumber: new FormControl(null)
        });
    }
}

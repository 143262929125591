import {Injectable} from '@angular/core';
import {BasicServiceInterface, FilteredListInterface} from "../../interfaces/global.interface";
import {
    CourseCustomerInterface,
    CourseCustomerUpdateInterface,
    CourseDateInterface, CourseDetailUploadCustomersReq,
    CourseDocumentInterface,
    CourseEmailDTOInterface, CourseExport,
    CourseInterface,
    CourseMoveCustomerReq,
    CourseRegistrationActiveCourseInterface,
    CourseSaveCustomerInterface,
    CourseSMSDTOInterface,
    CourseTrainerEmailDTOInterface
} from "../../interfaces/course.interface";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {Params} from '@angular/router';
import * as _ from 'underscore';
import {FileUploadService} from '../../services/file-upload.service';
import {ToastService} from '../../services/toast.service';
import {ApplicationFormCloseCourseRegistrationInterface, ApplicationFormOpenCourseRegistrationInterface} from '../../interfaces/application-form.interface';
import {UtilsService} from '../../services/utils.service';
import {FormTypesEnum} from '../../enums/form-types.enum';
import {TranslateService} from '../../services/translate.service';
import {TrainerInterface} from '../../interfaces/trainer.interface';
import {CertificateTemplateType} from '../../enums/certificate-template-type.enum';

export type BulkUpdateCustomerFlagByPropertyType = 'actualParticipation' | 'organizationInfoSent' | 'participationConfirm' | 'statementSent';

@Injectable({
    providedIn: 'root'
})
export class CoursesService implements BasicServiceInterface<CourseInterface> {

    constructor(private http: HttpClient,
                private fileUploadService: FileUploadService,
                private toastService: ToastService,
                private translateService: TranslateService) {
    }

    public downloadList(): Observable<CourseInterface[]> {
        return this.http.get<CourseInterface[]>(environment.apiPath + 'active-courses');
    }

    public downloadFilteredList(params: Params): Observable<FilteredListInterface<CourseInterface>> {
        return this.http.get<FilteredListInterface<CourseInterface>>(environment.apiPath + 'active-courses', {params});
    }

    public downloadPureFilteredList(params: HttpParams): Observable<CourseInterface[]> {
        return this.http.get<CourseInterface[]>(environment.apiPath + 'active-courses', {params});
    }

    public downloadActivesList(): Observable<CourseRegistrationActiveCourseInterface[]> {
        return this.http.get<CourseRegistrationActiveCourseInterface[]>(environment.apiPath + 'course-registrations/dictionary/active-courses');
    }

    public downloadOne(id: string): Observable<CourseInterface> {
        return this.http.get<CourseInterface>(environment.apiPath + 'active-courses/' + id);
    }

    public saveOne(data: CourseInterface): Observable<CourseInterface> {
        return this.http.post<CourseInterface>(environment.apiPath + 'active-courses', data);
    }

    public updateOne(data: CourseInterface, dataId: string): Observable<CourseInterface> {
        return this.http.put<CourseInterface>(environment.apiPath + 'active-courses/' + dataId, data);
    }

    public deleteOne(dataId: string): Observable<void> {
        return this.http.delete<void>(environment.apiPath + 'active-courses/' + dataId);
    }

    public duplicateOne(courseId: CourseInterface): Observable<CourseInterface> {
        return this.http.post<CourseInterface>(environment.apiPath + `active-courses/${courseId}/duplicate`, {});
    }

    public saveCustomerToActiveCourse(data: CourseSaveCustomerInterface, activeCourseId: string): Observable<CourseInterface> {
        return this.http.post<CourseInterface>(environment.apiPath + `active-courses/${activeCourseId}/customers`, data);
    }

    public saveCustomerToActiveCourseBulk(data: CourseDetailUploadCustomersReq, activeCourseId: string): Observable<void> {
        return this.http.post<void>(environment.apiPath + `active-courses/${activeCourseId}/customers/bulk`, data);
    }

    public deleteCustomerInActiveCourse(activeCourseId: string, courseCustomerId: string): Observable<void> {
        return this.http.delete<void>(environment.apiPath + `active-courses/${activeCourseId}/customers/${courseCustomerId}`);
    }

    public updateCourseCustomer(activeCourseId: string, customerId: string, data: CourseCustomerUpdateInterface): Observable<CourseInterface> {
        return this.http.put<CourseInterface>(environment.apiPath + `active-courses/${activeCourseId}/customers/${customerId}`, data);
    }

    public uploadDocumentToActiveCourse(activeCourseId: string, data: FormData): Observable<CourseDocumentInterface> {
        return this.http.post<CourseDocumentInterface>(environment.apiPath + `active-courses/${activeCourseId}/documents`, data);
    }

    public courseSendEmail(courseId: string, data: CourseEmailDTOInterface): Observable<CourseInterface> {
        return this.http.post<CourseInterface>(environment.apiPath + `active-courses/${courseId}/notification`, data);
    }

    public courseSendEmailToTrainers(courseId: string, data: CourseTrainerEmailDTOInterface): Observable<CourseInterface> {
        return this.http.post<CourseInterface>(environment.apiPath + `active-courses/${courseId}/trainers_notification`, data);
    }

    public courseSendSms(courseId: string, data: CourseSMSDTOInterface): Observable<void> {
        return this.http.post<void>(environment.apiPath + `active-course-actions/${courseId}/send_sms`, data);
    }

    public downloadCourseDocuments(id: string): Observable<CourseDocumentInterface[]> {
        return this.http.get<CourseDocumentInterface[]>(environment.apiPath + `active-courses/${id}/documents`);
    }

    public downloadCourseOneDocument(courseId: string, documentId: string): Observable<ArrayBuffer> {
        // @ts-ignore
        return this.http.get<Blob>(environment.apiPath + `active-courses/${courseId}/documents/${documentId}`, {responseType: 'blob'});
    }

    public deleteCourseOneDocument(courseId: string, documentId: string): Observable<void> {
        return this.http.delete<void>(environment.apiPath + `active-courses/${courseId}/documents/${documentId}`);
    }

    public deleteCourseDocumentsList(courseId: string, documents: string[]): Observable<void> {
        return this.http.post<void>(environment.apiPath + `active-courses/${courseId}/documents/delete-list`, {documents});
    }

    public generateCourseOrganizationCard(courseId: string): Observable<CourseDocumentInterface> {
        return this.http.post<CourseDocumentInterface>(environment.apiPath + `active-course-actions/${courseId}/create_organization`, {});
    }

    public generateCourseOrganizationCardDoc(courseId: string): Observable<CourseDocumentInterface> {
        return this.http.post<CourseDocumentInterface>(environment.apiPath + `active-course-actions/${courseId}/create_organization_doc`, {});
    }

    public generateCourseIndexId(courseId: string): Observable<CourseDocumentInterface> {
        return this.http.post<CourseDocumentInterface>(environment.apiPath + `active-course-actions/${courseId}/create_index_id`, {});
    }

    public generateCourseClosedPresentList(courseId: string): Observable<CourseDocumentInterface> {
        return this.http.post<CourseDocumentInterface>(environment.apiPath + `active-course-actions/${courseId}/create_closed_present_list`, {});
    }

    public generateCourseOpenPresentList(courseId: string): Observable<CourseDocumentInterface> {
        return this.http.post<CourseDocumentInterface>(environment.apiPath + `active-course-actions/${courseId}/create_open_present_list`, {});
    }

    public generateCourseCertifications(courseId: string, customers: string[]): Observable<CourseInterface> {
        return this.http.post<CourseInterface>(environment.apiPath + `active-course-actions/${courseId}/create_certificate`, {customers});
    }

    public generateCourseCertificationPreview(courseId: string, customers: string[], templateType: CertificateTemplateType): Observable<CourseDocumentInterface[]> {
        return this.http.post<CourseDocumentInterface[]>(environment.apiPath + `active-course-actions/${courseId}/create_certificate/preview`, {customers, templateType});
    }

    public updateRegistrationClose(registrationId: string, form: ApplicationFormCloseCourseRegistrationInterface): Observable<ApplicationFormCloseCourseRegistrationInterface> {
        return this.http.put<ApplicationFormCloseCourseRegistrationInterface>(environment.apiPath + 'course-registrations/closed/' + registrationId, form);
    }

    public updateRegistrationOpen(registrationId: string, form: ApplicationFormOpenCourseRegistrationInterface): Observable<ApplicationFormOpenCourseRegistrationInterface> {
        return this.http.put<ApplicationFormOpenCourseRegistrationInterface>(environment.apiPath + 'course-registrations/open/' + registrationId, form);
    }

    public deleteSavedPlacement(courseId: string, placementId: string): Observable<CourseInterface> {
        return this.http.delete<CourseInterface>(environment.apiPath + `active-courses/${courseId}/placements/${placementId}`);
    }

    public moveCustomerFromCourse(data: CourseMoveCustomerReq): Observable<void> {
        return this.http.post<void>(environment.apiPath + `active-course-actions/move_customer`, data);
    }

    public bulkUpdateCustomerFlagByProperty(activeCourseId: string, flag: boolean, customers: string[], property: BulkUpdateCustomerFlagByPropertyType): Observable<void> {
        return this.http.put<void>(environment.apiPath + `active-courses/${activeCourseId}/customers/bulk-update-customer-flag-by-property`, {flag, customers, property});
    }

    public static prepareCourses(courses: CourseInterface[]): CourseInterface[] {
        return courses.map((course: CourseInterface) => CoursesService.prepareCourse(course));
    }

    public static prepareCourse(course: CourseInterface): CourseInterface {
        course.dates = _.sortBy<CourseDateInterface[]>(course.dates, 'date');
        course.actualParticipantAmount = course.customers?.reduce((prev: number, curr: CourseCustomerInterface) => prev + (!curr.resignation ? 1 : 0), 0);
        course.actualPlacementAmount = course.properties?.placementRegistrationsEntities?.length;
        course.actualPlacementPredictedParticipantAmount = course.properties?.placementRegistrationsEntities?.reduce((prev: number, curr: ApplicationFormOpenCourseRegistrationInterface) => prev + (curr.participantCount || 0), 0);
        if (!course.filesToDownload) course.filesToDownload = [];
        if (!course.documents) course.documents = [];
        if (!!course.customers) course.customers.forEach((customer: CourseCustomerInterface) => {
            customer.selected = false;
            if (!customer.informationList) customer.informationList = {};
            for (let propName of ['organizationInfoSent', 'invoiceSent']) if (customer.informationList.hasOwnProperty(propName) && !customer.hasOwnProperty(propName)) customer[propName] = customer.informationList[propName];
        });

        return course;
    }

    public uploadDocumentActiveCourse(event: Event, courseId: string): Promise<CourseDocumentInterface> {
        return new Promise<CourseDocumentInterface>((resolve, reject) => {
            this.uploadDocumentToActiveCourse(courseId, this.prepareUploadDocumentActiveCourseDTO(event)).subscribe((document: CourseDocumentInterface) => {
                this.toastService.translateText('success', 'success.uploadDocument');
                resolve(document);
            });
        });
    }

    private prepareUploadDocumentActiveCourseDTO(event: Event): FormData {
        const file: File = this.fileUploadService.uploadFile(event)!;
        const formData: FormData = new FormData();
        formData.append('file', file);
        formData.append('name', file.name);

        return formData;
    }

    public prepareListForExcel(list: CourseInterface[], trainers: TrainerInterface[]): CourseExport[] {
        return list.map((course: CourseInterface) => {
            return {
                name: course.name,
                excelHourCount: course.hourCount,
                excelDates: course.dates.map((courseDate: CourseDateInterface) => `${courseDate.date} ${courseDate.startHour}-${courseDate.endHour}`).join('; '),
                excelLocalizations: course.dates.map((courseDate: CourseDateInterface) => (courseDate.plainLocalizations || []).join(', ')).join('; '),
                excelType: UtilsService.ifCourseClosed(course.type) ? this.translateService.getTranslated('courseClosed') : this.translateService.getTranslated('courseOpen'),
                excelForm: this.translateService.getTranslated(`enum.formTypes.${FormTypesEnum[course.form]}`),
                excelClient: UtilsService.ifCourseClosed(course.type) ? course.properties.sourceRegistrationEntity?.addressData?.name : '',
                excelClientCity: UtilsService.ifCourseClosed(course.type) ? course.properties.sourceRegistrationEntity?.addressData?.city : '',
                excelStatus: this.translateService.getTranslated(`enum.courseStatuses.${course.courseStatus.toUpperCase()}`),
                excelTrainers: course.dates.map((courseDate: CourseDateInterface) => CoursesService.prepareListForExcelTrainers(courseDate, trainers).join('; ')).join('; '),
                excelActualParticipantAmount: course.actualParticipantAmount || 0,
                excelActualPlacementAmount: course.actualPlacementAmount || 0,
                excelActualPlacementPredictedParticipantAmount: course.actualPlacementPredictedParticipantAmount || 0,
                excelActualAllPredictedParticipantAmount: (course.actualPlacementPredictedParticipantAmount || 0) + (course.actualParticipantAmount || 0),
            }
        });
    }

    private static prepareListForExcelTrainers(courseDate: CourseDateInterface, trainerList: TrainerInterface[]): (string | undefined)[] {
        return courseDate.trainers.map((trainerId: string) => {
            const foundedTrainer: TrainerInterface = trainerList.find((trainer: TrainerInterface) => trainer.id === trainerId)!;
            return !!foundedTrainer ? `${foundedTrainer.firstName} ${foundedTrainer.lastName}` : undefined;
        });
    }
}
